import React from "react"
import * as NavigationStyle from "../styles/navigation.module.css"

import MenuIcon from '../assets/svg/menu.svg';

const NavigationItems = [
  {
    href: "#",
    label: "Steimers",
  },
]

export default class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSticky: false,
      responsiveMenuVisible: false,
    }

    this.stickyOffset = 0;
    this.navBarRef = React.createRef();
    this.onScrollListener = this.onScrollListener.bind(this);
  }

  onScrollListener() {
    if (window.pageYOffset > this.stickyOffset) {
      this.setState({isSticky: true});
    } else {
      this.setState({isSticky: false});
    }
  }

  componentDidMount() {
    this.stickyOffset = this.navBarRef.current.offsetTop;
    window.addEventListener('scroll', this.onScrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollListener);
  }

  renderNavigationLinks() {
    return NavigationItems.map(item =>
      <a href={item.href} key={item.label} className={NavigationStyle.navigationLink}>{item.label}</a>,
    )
  }

  render() {

    const toggleResponsiveMenu = () => {
      const isVisible = !this.state.responsiveMenuVisible;
      this.setState({
        responsiveMenuVisible: isVisible
      });
    }

    const navStickyClass = this.state.isSticky ? `${NavigationStyle.navigation} ${NavigationStyle.navigationSticky}` : `${NavigationStyle.navigation}`;
    const navResponsiveClass = this.state.responsiveMenuVisible ? `${NavigationStyle.responsive}` : '';
    const navClassName = `${navStickyClass} ${navResponsiveClass}`

    return (
      <nav className={navClassName} ref={this.navBarRef}>
        {this.renderNavigationLinks()}
        <button className={NavigationStyle.menuButton} onClick={toggleResponsiveMenu}>
          <MenuIcon width={'20px'} height={'20px'}/>
        </button>
      </nav>
    )
  }
}
